function IconAppStoreOutline(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg viewBox='0 0 160 46' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M153.333 0.921437C156.064 0.921437 158.286 3.08775 158.286 5.75V40.25C158.286 42.9123 156.064 45.0786 153.333 45.0786H5.89744C3.16692 45.0786 0.945064 42.9123 0.945064 40.25V5.75C0.945064 3.08775 3.16692 0.921437 5.89744 0.921437H153.333ZM153.333 2.43107e-06H5.89744C2.65532 2.43107e-06 0 2.58894 0 5.75V40.25C0 43.4111 2.65532 46 5.89744 46H153.333C156.575 46 159.231 43.4111 159.231 40.25V5.75C159.231 2.58894 156.575 2.43107e-06 153.333 2.43107e-06Z'
        fill='white'
      />
      <path
        d='M35.5356 22.751C35.5014 19.0445 38.6483 17.2413 38.7922 17.1574C37.01 14.6239 34.2476 14.2778 33.2769 14.2502C30.9568 14.0121 28.7064 15.6037 27.5245 15.6037C26.3191 15.6037 24.4991 14.2732 22.5376 14.3123C20.0135 14.3502 17.6522 15.7751 16.3571 17.9877C13.6844 22.4991 15.6777 29.1289 18.2384 32.7755C19.5193 34.5615 21.0161 36.5556 22.9752 36.4854C24.8919 36.4084 25.6078 35.294 27.9208 35.294C30.2126 35.294 30.8849 36.4854 32.8829 36.4406C34.9399 36.4084 36.235 34.6466 37.4711 32.8445C38.9514 30.7975 39.5458 28.7816 39.5694 28.6781C39.5211 28.662 35.5745 27.1934 35.5356 22.751Z'
        fill='white'
      />
      <path
        d='M31.7612 11.8513C32.7921 10.5943 33.4974 8.88428 33.3016 7.14893C31.8096 7.21333 29.9436 8.15518 28.8691 9.38453C27.9185 10.4678 27.0692 12.2434 27.2886 13.9132C28.9647 14.0351 30.6855 13.0887 31.7612 11.8513Z'
        fill='white'
      />
      <path
        d='M63.2736 36.2285H60.595L59.1277 31.7332H54.0276L52.6299 36.2285H50.022L55.075 20.9243H58.1959L63.2736 36.2285ZM58.6854 29.8472L57.3584 25.8509C57.2181 25.4427 56.9551 24.4813 56.567 22.9679H56.5198C56.3653 23.6188 56.1164 24.5802 55.7744 25.8509L54.4711 29.8472H58.6854Z'
        fill='white'
      />
      <path
        d='M76.2681 30.5753C76.2681 32.4521 75.7479 33.9356 74.7076 35.0247C73.7758 35.9941 72.6187 36.4783 71.2375 36.4783C69.7467 36.4783 68.6757 35.9562 68.0234 34.912H67.9763V40.7252H65.4616V28.8262C65.4616 27.6463 65.4297 26.4353 65.3684 25.1933H67.5799L67.7203 26.9425H67.7675C68.6061 25.6246 69.8788 24.9668 71.5867 24.9668C72.9218 24.9668 74.0365 25.4808 74.9282 26.5101C75.8222 27.5405 76.2681 28.8952 76.2681 30.5753ZM73.7062 30.665C73.7062 29.5909 73.4585 28.7054 72.9608 28.0085C72.417 27.2817 71.6869 26.9183 70.7716 26.9183C70.1512 26.9183 69.5874 27.1207 69.0838 27.5198C68.579 27.9223 68.2487 28.4478 68.0942 29.0987C68.0164 29.4023 67.9774 29.6507 67.9774 29.8462V31.6862C67.9774 32.4889 68.2298 33.1663 68.7347 33.7194C69.2395 34.2726 69.8953 34.5486 70.702 34.5486C71.6492 34.5486 72.3864 34.1921 72.9136 33.4814C73.442 32.7695 73.7062 31.8311 73.7062 30.665Z'
        fill='white'
      />
      <path
        d='M89.2859 30.5753C89.2859 32.4521 88.7658 33.9356 87.7243 35.0247C86.7937 35.9941 85.6366 36.4783 84.2554 36.4783C82.7646 36.4783 81.6936 35.9562 81.0425 34.912H80.9953V40.7252H78.4807V28.8262C78.4807 27.6463 78.4488 26.4353 78.3875 25.1933H80.599L80.7394 26.9425H80.7866C81.624 25.6246 82.8967 24.9668 84.6057 24.9668C85.9397 24.9668 87.0544 25.4808 87.9484 26.5101C88.8389 27.5405 89.2859 28.8952 89.2859 30.5753ZM86.7241 30.665C86.7241 29.5909 86.4752 28.7054 85.9775 28.0085C85.4337 27.2817 84.706 26.9183 83.7895 26.9183C83.1679 26.9183 82.6053 27.1207 82.1005 27.5198C81.5957 27.9223 81.2666 28.4478 81.1121 29.0987C81.0354 29.4023 80.9953 29.6507 80.9953 29.8462V31.6862C80.9953 32.4889 81.2477 33.1663 81.7502 33.7194C82.255 34.2714 82.9108 34.5486 83.7199 34.5486C84.6671 34.5486 85.4043 34.1921 85.9315 33.4814C86.4599 32.7695 86.7241 31.8311 86.7241 30.665Z'
        fill='white'
      />
      <path
        d='M103.841 31.9373C103.841 33.2391 103.377 34.2983 102.447 35.1159C101.424 36.0095 100.001 36.4557 98.1711 36.4557C96.4821 36.4557 95.1281 36.1383 94.1031 35.5023L94.6858 33.4588C95.7898 34.1097 97.0011 34.4363 98.3209 34.4363C99.2681 34.4363 100.005 34.227 100.535 33.8107C101.062 33.3944 101.325 32.8355 101.325 32.1386C101.325 31.5176 101.108 30.9943 100.673 30.57C100.24 30.1456 99.5169 29.7512 98.5073 29.3866C95.7591 28.3873 94.3862 26.9233 94.3862 24.9982C94.3862 23.7401 94.8674 22.7086 95.831 21.9059C96.7911 21.102 98.0721 20.7007 99.6738 20.7007C101.102 20.7007 102.289 20.9433 103.236 21.4275L102.607 23.4262C101.723 22.957 100.722 22.7224 99.603 22.7224C98.7184 22.7224 98.0272 22.9351 97.5319 23.3583C97.1131 23.7367 96.9032 24.1978 96.9032 24.7441C96.9032 25.349 97.1426 25.8492 97.6239 26.2425C98.0426 26.6059 98.8033 26.9992 99.9073 27.4236C101.258 27.9537 102.25 28.5736 102.888 29.2843C103.524 29.9927 103.841 30.8793 103.841 31.9373Z'
        fill='white'
      />
      <path
        d='M112.155 27.0335H109.383V32.3913C109.383 33.7541 109.872 34.4349 110.85 34.4349C111.3 34.4349 111.673 34.3969 111.967 34.321L112.037 36.1829C111.542 36.3634 110.889 36.4543 110.081 36.4543C109.088 36.4543 108.312 36.1587 107.752 35.5688C107.194 34.9777 106.913 33.9864 106.913 32.5937V27.0312H105.262V25.1912H106.913V23.1706L109.383 22.4438V25.1912H112.155V27.0335Z'
        fill='white'
      />
      <path
        d='M124.661 30.6205C124.661 32.3168 124.163 33.7094 123.17 34.7985C122.129 35.9197 120.746 36.4786 119.023 36.4786C117.363 36.4786 116.04 35.9416 115.054 34.8675C114.068 33.7934 113.575 32.4375 113.575 30.8034C113.575 29.0933 114.082 27.6926 115.1 26.6036C116.116 25.5134 117.486 24.9683 119.21 24.9683C120.87 24.9683 122.207 25.5053 123.215 26.5806C124.18 27.6236 124.661 28.9703 124.661 30.6205ZM122.052 30.6999C122.052 29.6821 121.829 28.8093 121.377 28.0813C120.85 27.2004 120.097 26.7611 119.12 26.7611C118.109 26.7611 117.341 27.2016 116.814 28.0813C116.362 28.8104 116.139 29.6971 116.139 30.7459C116.139 31.7636 116.362 32.6365 116.814 33.3633C117.358 34.2442 118.117 34.6835 119.098 34.6835C120.058 34.6835 120.811 34.235 121.355 33.3403C121.819 32.5985 122.052 31.7165 122.052 30.6999Z'
        fill='white'
      />
      <path
        d='M132.835 27.3496C132.586 27.3047 132.321 27.2817 132.042 27.2817C131.158 27.2817 130.474 27.6072 129.992 28.2592C129.574 28.8342 129.364 29.561 129.364 30.4385V36.2287H126.85L126.874 28.6686C126.874 27.3967 126.842 26.2387 126.779 25.1945H128.97L129.062 27.3059H129.131C129.397 26.5802 129.815 25.996 130.389 25.5579C130.949 25.1634 131.554 24.9668 132.206 24.9668C132.439 24.9668 132.649 24.9829 132.835 25.0116V27.3496Z'
        fill='white'
      />
      <path
        d='M144.081 30.1901C144.081 30.6294 144.052 30.9997 143.989 31.3022H136.445C136.475 32.3924 136.839 33.2261 137.54 33.8011C138.176 34.3152 138.998 34.5728 140.007 34.5728C141.124 34.5728 142.143 34.3991 143.06 34.0507L143.454 35.7527C142.383 36.2081 141.118 36.4346 139.659 36.4346C137.904 36.4346 136.527 35.9309 135.524 34.9247C134.524 33.9184 134.023 32.5672 134.023 30.8721C134.023 29.208 134.488 27.8223 135.421 26.7171C136.398 25.5372 137.718 24.9473 139.379 24.9473C141.01 24.9473 142.245 25.5372 143.083 26.7171C143.747 27.6544 144.081 28.8136 144.081 30.1901ZM141.683 29.5542C141.7 28.8274 141.536 28.1995 141.195 27.6693C140.76 26.9874 140.091 26.647 139.191 26.647C138.369 26.647 137.7 26.9793 137.19 27.6463C136.771 28.1765 136.522 28.8124 136.445 29.553H141.683V29.5542Z'
        fill='white'
      />
      <path
        d='M57.8541 11.51C57.8541 12.8636 57.4377 13.8825 56.6062 14.5667C55.836 15.1981 54.7414 15.5143 53.3237 15.5143C52.6207 15.5143 52.0191 15.4844 51.5155 15.4246V8.02896C52.1725 7.92546 52.8802 7.87256 53.6445 7.87256C54.995 7.87256 56.0129 8.15891 56.6994 8.73161C57.4684 9.37906 57.8541 10.3048 57.8541 11.51ZM56.5507 11.5434C56.5507 10.6659 56.3125 9.99316 55.836 9.52396C55.3595 9.05591 54.6636 8.82131 53.7471 8.82131C53.3579 8.82131 53.0264 8.84661 52.7516 8.89951V14.5219C52.9038 14.5449 53.1821 14.5552 53.5867 14.5552C54.5326 14.5552 55.2627 14.2988 55.777 13.7859C56.2913 13.273 56.5507 12.5255 56.5507 11.5434Z'
        fill='white'
      />
      <path
        d='M64.7647 12.6921C64.7647 13.5259 64.5205 14.209 64.0322 14.7449C63.5203 15.2957 62.8421 15.5706 61.9952 15.5706C61.179 15.5706 60.5291 15.3072 60.0444 14.7782C59.5608 14.2504 59.319 13.5845 59.319 12.7818C59.319 11.9423 59.5678 11.2535 60.0679 10.7187C60.568 10.184 61.2404 9.91602 62.0872 9.91602C62.9034 9.91602 63.5592 10.1794 64.0558 10.7072C64.5276 11.2201 64.7647 11.8825 64.7647 12.6921ZM63.4826 12.7312C63.4826 12.231 63.3717 11.802 63.1511 11.4444C62.8916 11.012 62.5225 10.7958 62.0424 10.7958C61.5458 10.7958 61.1684 11.012 60.9089 11.4444C60.6872 11.802 60.5775 12.2379 60.5775 12.7531C60.5775 13.2533 60.6884 13.6823 60.9089 14.0399C61.1767 14.4723 61.5494 14.6885 62.0306 14.6885C62.5024 14.6885 62.8728 14.4689 63.1393 14.0284C63.3682 13.6639 63.4826 13.2315 63.4826 12.7312Z'
        fill='white'
      />
      <path
        d='M74.0307 10.0264L72.291 15.4475H71.1587L70.438 13.0934C70.2552 12.5058 70.1066 11.9216 69.991 11.342H69.9686C69.8613 11.9377 69.7126 12.5207 69.5216 13.0934L68.7561 15.4475H67.6108L65.9749 10.0264H67.2452L67.8738 12.6035C68.026 13.213 68.151 13.7938 68.2513 14.3435H68.2737C68.3657 13.8904 68.5178 13.3131 68.7325 12.615L69.5216 10.0275H70.5289L71.2849 12.5598C71.4677 13.1774 71.6163 13.7719 71.7307 14.3446H71.765C71.8487 13.7869 71.9749 13.1923 72.1424 12.5598L72.8171 10.0275H74.0307V10.0264Z'
        fill='white'
      />
      <path
        d='M80.4389 15.4475H79.2028V12.3425C79.2028 11.3857 78.8301 10.9073 78.0823 10.9073C77.7155 10.9073 77.4195 11.0384 77.1895 11.3018C76.9618 11.5651 76.8462 11.8756 76.8462 12.231V15.4464H75.6101V11.5755C75.6101 11.0994 75.5948 10.583 75.5653 10.0241H76.6516L76.7094 10.8717H76.7436C76.8875 10.6083 77.1022 10.391 77.3841 10.2173C77.719 10.0149 78.0941 9.9126 78.5046 9.9126C79.0236 9.9126 79.4552 10.0759 79.7985 10.4036C80.2254 10.805 80.4389 11.4041 80.4389 12.1999V15.4475Z'
        fill='white'
      />
      <path d='M83.8476 15.4476H82.6127V7.53906H83.8476V15.4476Z' fill='white' />
      <path
        d='M91.125 12.6921C91.125 13.5259 90.8809 14.209 90.3926 14.7449C89.8807 15.2957 89.2013 15.5706 88.3556 15.5706C87.5382 15.5706 86.8883 15.3072 86.4047 14.7782C85.9211 14.2504 85.6793 13.5845 85.6793 12.7818C85.6793 11.9423 85.9282 11.2535 86.4283 10.7187C86.9284 10.184 87.6007 9.91602 88.4464 9.91602C89.2638 9.91602 89.9184 10.1794 90.4161 10.7072C90.8879 11.2201 91.125 11.8825 91.125 12.6921ZM89.8417 12.7312C89.8417 12.231 89.7309 11.802 89.5103 11.4444C89.252 11.012 88.8816 10.7958 88.4028 10.7958C87.905 10.7958 87.5276 11.012 87.2693 11.4444C87.0475 11.802 86.9378 12.2379 86.9378 12.7531C86.9378 13.2533 87.0487 13.6823 87.2693 14.0399C87.537 14.4723 87.9097 14.6885 88.391 14.6885C88.8628 14.6885 89.2319 14.4689 89.4985 14.0284C89.7285 13.6639 89.8417 13.2315 89.8417 12.7312Z'
        fill='white'
      />
      <path
        d='M97.1075 15.4475H95.9976L95.9056 14.8231H95.8714C95.4916 15.321 94.9502 15.5706 94.2472 15.5706C93.7223 15.5706 93.2977 15.4061 92.9781 15.0795C92.6879 14.7828 92.5428 14.4137 92.5428 13.9755C92.5428 13.3131 92.8259 12.8083 93.3956 12.4587C93.9641 12.1091 94.7638 11.9377 95.7935 11.9458V11.8446C95.7935 11.1304 95.409 10.7739 94.6388 10.7739C94.0903 10.7739 93.6067 10.9085 93.1892 11.1753L92.938 10.3841C93.4546 10.0724 94.0927 9.91602 94.8452 9.91602C96.2983 9.91602 97.0273 10.6635 97.0273 12.1585V14.1549C97.0273 14.6966 97.0544 15.1278 97.1075 15.4475ZM95.8242 13.5845V12.7485C94.4607 12.7255 93.779 13.09 93.779 13.841C93.779 14.1239 93.8568 14.3355 94.016 14.4769C94.1753 14.6184 94.3781 14.6885 94.6199 14.6885C94.8912 14.6885 95.1448 14.6046 95.376 14.4378C95.6083 14.2699 95.751 14.0572 95.8041 13.7961C95.8171 13.7375 95.8242 13.6662 95.8242 13.5845Z'
        fill='white'
      />
      <path
        d='M104.131 15.4476H103.034L102.976 14.5771H102.942C102.592 15.2395 101.995 15.5707 101.157 15.5707C100.487 15.5707 99.9287 15.3142 99.4864 14.8013C99.044 14.2884 98.8235 13.6226 98.8235 12.8049C98.8235 11.9275 99.0629 11.2168 99.5442 10.674C100.01 10.168 100.581 9.91496 101.26 9.91496C102.007 9.91496 102.529 10.1599 102.827 10.651H102.85V7.53906H104.088V13.9871C104.088 14.515 104.102 15.0014 104.131 15.4476ZM102.85 13.1614V12.2575C102.85 12.1011 102.838 11.9746 102.816 11.878C102.746 11.5882 102.597 11.3444 102.369 11.1478C102.139 10.9511 101.862 10.8522 101.542 10.8522C101.081 10.8522 100.72 11.0305 100.455 11.3881C100.192 11.7458 100.058 12.2023 100.058 12.7601C100.058 13.296 100.185 13.7307 100.438 14.0653C100.706 14.4218 101.067 14.6001 101.519 14.6001C101.924 14.6001 102.249 14.4517 102.495 14.1539C102.733 13.879 102.85 13.5478 102.85 13.1614Z'
        fill='white'
      />
      <path
        d='M114.703 12.6921C114.703 13.5259 114.459 14.209 113.97 14.7449C113.459 15.2957 112.782 15.5706 111.933 15.5706C111.118 15.5706 110.469 15.3072 109.983 14.7782C109.499 14.2504 109.257 13.5845 109.257 12.7818C109.257 11.9423 109.506 11.2535 110.006 10.7187C110.506 10.184 111.179 9.91602 112.027 9.91602C112.842 9.91602 113.499 10.1794 113.994 10.7072C114.466 11.2201 114.703 11.8825 114.703 12.6921ZM113.422 12.7312C113.422 12.231 113.311 11.802 113.091 11.4444C112.83 11.012 112.462 10.7958 111.981 10.7958C111.485 10.7958 111.108 11.012 110.847 11.4444C110.625 11.802 110.516 12.2379 110.516 12.7531C110.516 13.2533 110.627 13.6823 110.847 14.0399C111.115 14.4723 111.488 14.6885 111.969 14.6885C112.441 14.6885 112.812 14.4689 113.079 14.0284C113.306 13.6639 113.422 13.2315 113.422 12.7312Z'
        fill='white'
      />
      <path
        d='M121.349 15.4475H120.114V12.3425C120.114 11.3857 119.742 10.9073 118.993 10.9073C118.626 10.9073 118.33 11.0384 118.101 11.3018C117.872 11.5651 117.758 11.8756 117.758 12.231V15.4464H116.521V11.5755C116.521 11.0994 116.506 10.583 116.477 10.0241H117.562L117.62 10.8717H117.654C117.799 10.6083 118.014 10.391 118.294 10.2173C118.631 10.0149 119.005 9.9126 119.416 9.9126C119.934 9.9126 120.366 10.0759 120.709 10.4036C121.137 10.805 121.349 11.4041 121.349 12.1999V15.4475Z'
        fill='white'
      />
      <path
        d='M129.668 10.9291H128.307V13.5626C128.307 14.2319 128.549 14.5666 129.028 14.5666C129.25 14.5666 129.434 14.5482 129.579 14.5102L129.61 15.4245C129.366 15.5142 129.046 15.559 128.65 15.559C128.162 15.559 127.782 15.4141 127.507 15.1243C127.231 14.8345 127.095 14.3469 127.095 13.6627V10.9291H126.282V10.0264H127.095V9.03277L128.306 8.67627V10.0252H129.667V10.9291H129.668Z'
        fill='white'
      />
      <path
        d='M136.212 15.4476H134.975V12.3656C134.975 11.3939 134.602 10.9074 133.855 10.9074C133.282 10.9074 132.891 11.1892 132.676 11.7527C132.639 11.8711 132.618 12.016 132.618 12.1862V15.4465H131.383V7.53906H132.618V10.8062H132.642C133.031 10.2117 133.589 9.91496 134.312 9.91496C134.824 9.91496 135.247 10.0783 135.583 10.406C136.002 10.8143 136.212 11.4215 136.212 12.2242V15.4476Z'
        fill='white'
      />
      <path
        d='M142.962 12.4801C142.962 12.6963 142.946 12.878 142.916 13.0264H139.209C139.226 13.5623 139.403 13.9705 139.746 14.2534C140.06 14.5064 140.464 14.6329 140.96 14.6329C141.508 14.6329 142.008 14.5478 142.459 14.3765L142.652 15.2137C142.125 15.4368 141.504 15.5483 140.786 15.5483C139.925 15.5483 139.247 15.3011 138.756 14.8066C138.263 14.3121 138.019 13.6485 138.019 12.8159C138.019 11.9983 138.247 11.3175 138.706 10.7747C139.184 10.1951 139.832 9.90527 140.649 9.90527C141.449 9.90527 142.056 10.1951 142.467 10.7747C142.798 11.2347 142.962 11.8039 142.962 12.4801ZM141.783 12.1685C141.792 11.8108 141.711 11.5026 141.543 11.2427C141.329 10.9081 141.002 10.7402 140.56 10.7402C140.156 10.7402 139.827 10.9035 139.576 11.2312C139.371 11.4923 139.249 11.8039 139.209 12.1685H141.783Z'
        fill='white'
      />
    </svg>
  );
}

export default IconAppStoreOutline;
