import { HTMLAttributes } from 'react';

export default function BrandSuperligaFirem(props: HTMLAttributes<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 800 327' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M3.70273 3.37L3.68774 228.617L140.093 321.162L276.499 228.846V3.37H3.70273Z'
        fill='#3BFEB8'
      />
      <path
        d='M60.1828 69.8528L70.3915 80.0326H80.2354V70.2117L70.0317 60.027L80.2354 49.8471V40.0213H70.3915L60.1828 50.2061L49.9741 40.0213H40.1302V49.8471L50.3389 60.027L40.1302 70.2117V80.0326H49.9741L60.1828 69.8528Z'
        fill='#212D5E'
      />
      <path
        d='M220.609 69.8528L230.817 80.0326H240.661V70.2117L230.457 60.027L240.661 49.8471V40.0213H230.817L220.609 50.2061L210.405 40.0213H200.556V49.8471L210.765 60.027L200.556 70.2117V80.0326H210.405L220.609 69.8528Z'
        fill='#212D5E'
      />
      <path
        d='M60.1828 187.015L70.3915 197.2H80.2354V187.374L70.0317 177.189L80.2354 167.01V157.184H70.3915L60.1828 167.369L49.9741 157.184H40.1302V167.01L50.3389 177.189L40.1302 187.374V197.2H49.9741L60.1828 187.015Z'
        fill='#212D5E'
      />
      <path
        d='M220.609 187.015L230.817 197.2H240.661V187.374L230.457 177.189L240.661 167.01V157.184H230.817L220.609 167.369L210.405 157.184H200.556V167.01L210.765 177.189L200.556 187.374V197.2H210.405L220.609 187.015Z'
        fill='#212D5E'
      />
      <path
        d='M130.194 255.781L140.398 245.596L150.602 255.781H160.451V245.955L150.242 235.771L160.451 225.591V215.765H150.602L140.398 225.95L130.194 215.765H120.346V225.591L130.549 235.771L120.346 245.955V255.781H130.194Z'
        fill='#212D5E'
      />
      <path
        d='M140.398 128.459L150.602 138.644H160.451V128.818L150.242 118.638L160.451 108.453V98.6274H150.602L140.398 108.812L130.189 98.6274H120.346V108.453L130.549 118.638L120.346 128.818V138.644H130.189L140.398 128.459Z'
        fill='#212D5E'
      />
      <path
        d='M140.388 327L0 231.748L0.0149907 0H280.776V231.987L140.388 327ZM13.2618 224.718L140.388 310.843L267.405 224.803V13.3404H13.3867L13.2618 224.718Z'
        fill='#212D5E'
      />
      <path
        d='M312.182 87.6201C312.182 86.7327 312.182 86.648 312.272 86.4685L326.813 84.6988V85.3169C326.813 89.3849 328.322 90.8007 332.754 90.8007H343.393C347.825 90.8007 349.334 89.3849 349.334 85.2272V83.6369C349.334 80.1871 348.095 79.2998 342.328 78.4174L327.607 76.2937C316.614 74.7034 312.447 70.81 312.447 61.7867V54.269C312.447 43.7402 317.853 38.5207 328.762 38.5207H347.38C358.288 38.5207 363.695 43.7402 363.695 54.1793C363.695 54.9769 363.695 55.0617 363.605 55.3309L349.064 57.1006V56.4825C349.064 52.4145 347.56 50.9987 343.123 50.9987H333.194C328.672 50.9987 327.078 52.5043 327.078 56.5722V57.8983C327.078 61.4378 328.142 62.1457 334.168 63.028L348.889 65.1517C359.972 66.742 363.965 70.6355 363.965 79.8382V87.5353C363.965 98.0591 358.553 103.284 347.74 103.284H328.412C317.594 103.284 312.187 98.0641 312.187 87.6251L312.182 87.6201Z'
        fill='#212D5E'
      />
      <path
        d='M423.323 87.5304C423.323 98.0541 417.911 103.279 407.008 103.279H389.009C378.191 103.279 372.784 98.0591 372.784 87.5304V39.4081H387.415V85.2322C387.415 89.3898 388.919 90.8056 393.357 90.8056H402.756C407.188 90.8056 408.697 89.3898 408.697 85.2322V39.4081H423.328V87.5304H423.323Z'
        fill='#212D5E'
      />
      <path
        d='M482.566 55.1564V64.2694C482.566 74.7931 477.16 80.0127 466.252 80.0127H447.543V102.391H432.912V39.4081H466.252C477.16 39.4081 482.566 44.6276 482.566 55.1564ZM467.941 57.4546C467.941 53.2969 466.431 51.8811 461.999 51.8811H447.548V67.5397H461.999C466.431 67.5397 467.941 66.1239 467.941 61.9662V57.4546Z'
        fill='#212D5E'
      />
      <path
        d='M504.813 51.8811V63.9104H531.146V76.3834H504.813V89.9183H534.869V102.391H490.182V39.4081H534.869V51.8811H504.813Z'
        fill='#212D5E'
      />
      <path
        d='M565.525 79.569H557.28V102.391H542.649V39.4081H576.253C587.072 39.4081 592.478 44.6276 592.478 55.1564V63.9154C592.478 72.141 589.111 77.0963 582.37 78.8661L598.949 102.396H581.84L565.525 79.574V79.569ZM557.28 67.096H571.911C576.343 67.096 577.853 65.6802 577.853 61.5225V57.4546C577.853 53.2969 576.343 51.8811 571.911 51.8811H557.28V67.096Z'
        fill='#212D5E'
      />
      <path d='M649.958 89.9183V102.391H606.335V39.4081H620.966V89.9183H649.958Z' fill='#212D5E' />
      <path d='M657.278 39.4081H671.909V102.391H657.278V39.4081Z' fill='#212D5E' />
      <path
        d='M703.85 66.2983H732.577V87.6151C732.577 98.0541 727.17 103.274 716.262 103.274H697.199C686.38 103.274 680.974 98.0541 680.974 87.5254V54.264C680.974 43.7353 686.385 38.5157 697.199 38.5157H716.262C727.17 38.5157 732.577 43.7353 732.577 54.1743C732.577 54.7925 732.577 55.0567 732.487 55.3259L717.946 57.0956V56.4775C717.946 52.4095 716.442 50.9937 712.005 50.9937H701.541C697.109 50.9937 695.6 52.4095 695.6 56.5672V85.2272C695.6 89.3849 697.109 90.8007 701.541 90.8007H712.005C716.437 90.8007 717.946 89.3849 717.946 85.3169V78.7713H703.85V66.2983Z'
        fill='#212D5E'
      />
      <path
        d='M780.492 87.974H757.436L752.914 102.391H737.928L758.321 39.4081H779.688L799.995 102.391H785.009L780.487 87.974H780.492ZM776.679 75.5858L768.964 50.9937L761.339 75.5858H776.679Z'
        fill='#212D5E'
      />
      <path
        d='M328.347 158.031H354.651V170.489H328.347V194.613H313.731V131.694H358.373V144.152H328.347V158.031Z'
        fill='#212D5E'
      />
      <path d='M371.04 131.699H385.656V194.618H371.04V131.699Z' fill='#212D5E' />
      <path
        d='M424.807 171.815H416.567V194.613H401.951V131.694H435.521C446.324 131.694 451.731 136.909 451.731 147.423V156.172C451.731 164.387 448.363 169.338 441.632 171.107L458.197 194.613H441.102L424.807 171.815ZM416.567 159.357H431.183C435.611 159.357 437.12 157.942 437.12 153.789V149.726C437.12 145.573 435.616 144.157 431.183 144.157H416.567V159.357Z'
        fill='#212D5E'
      />
      <path
        d='M484.505 156.177H510.809V168.635H484.505V182.155H514.532V194.613H469.889V131.694H514.532V144.152H484.505V156.177Z'
        fill='#212D5E'
      />
      <path
        d='M591.329 131.699V194.618H576.713V155.648L561.743 179.064H556.516L541.635 155.823V194.613H527.019V131.694H541.19L559.169 159.796L577.063 131.694H591.324L591.329 131.699Z'
        fill='#212D5E'
      />
    </svg>
  );
}
